@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Global e Estrutura */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

/* Variáveis de Cores */
:root {
  --bege-claro: #f0e5d5;  /* Bege Claro */
  --marrom-claro: #AF8260;  /* Marrom Claro */
  --bordo: #803D3B;  /* Bordô */
  --preto: #322C2B;  /* Preto */
}

#app, main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%; /* Evita overflow */
  overflow-x: hidden;
}

/* Adicione ao início do CSS */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

/* Variáveis de Fontes */
body, input {
  font-family: "Montserrat", sans-serif;
}

/* Estilo Global */
body {
  font-size: 1.6rem;
  line-height: 1.6;
  background: var(--bege-claro);  /* Fundo principal */
  color: var(--preto);  /* Texto principal */
}

/* Header */
.header-content {
  background: var(--bege-claro);  /* Fundo do header */
  padding: 1.25rem; /* Convertido de 20px */
  border-bottom: 0.25rem solid var(--bordo);  /* Convertido de 4px */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.site-title {
  font-size: 3.4375rem; /* Convertido de 55px */
  color: var(--preto);  /* Cor do título */
  margin-bottom: 0.5rem; /* Mantido como rem */
  font-family: "Dancing Script", cursive;
  font-weight: 700;
}

.site-subtitle {
  font-size: 2rem; /* Convertido de 32px */
  color: var(--bordo);  /* Subtítulo com Bordô */
  text-align: center;
  font-family: "Dancing Script", cursive;
  font-weight: 500;
}

/* Navbar */
.navbar {
  background: var(--bordo);  /* Fundo da navbar */
  border-bottom: 0.125rem solid var(--marrom-claro); /* Convertido de 2px */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* Convertido de 4px e 8px */
}

.navbar ul {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  margin: 0;
  list-style: none;
  gap: 2rem;
}

.navbar ul li {
  position: relative;  /* Necessário para o dropdown */
  transition: transform 0.3s ease;
}

.navbar ul li:hover {
  transform: translateY(-0.125rem); /* Convertido de 2px */
}

.navbar ul li a {
  text-decoration: none;
  color: var(--bege-claro);  /* Texto da navbar */
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  transition: background 0.3s, color 0.3s, transform 0.3s;
}

.navbar ul li a:hover {
  background: var(--marrom-claro);
  color: var(--preto);  /* Texto no hover */
  border-radius: 0.3125rem; /* Convertido de 5px */
}

/* Dropdown Menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--marrom-claro); /* Cor de fundo */
  padding: 0.5rem 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); /* Convertido de 8px e 16px */
  border-radius: 0.3125rem; /* Convertido de 5px */
  z-index: 20;
  min-width: 12.5rem; /* Convertido de 200px */
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dropdown:hover .dropdown-menu {
  display: flex;
  opacity: 1;
  visibility: visible;
}

.dropdown-menu li a {
  padding: 0.7rem 1.5rem;
  display: block;
  color: var(--bege-claro);
  text-align: left;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
  white-space: nowrap;
  border: none;
  outline: none;
  box-shadow: none;
}

.dropdown-menu li a:hover {
  background: var(--bordo);
  color: var(--preto); 
  border: none; 
  border-radius: 0px; 
  box-shadow: none;
}

/* Galeria e Títulos */
.gallery-container {
  text-align: center;
  margin-bottom: 2rem;
}

.titulo-lampejos {
  font-size: 3rem;
  color: var(--bordo);
  font-family: 'Dancing Script', cursive;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* Organização dos Quadrados */
.quadrados-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-items: center;
}

/* Estilos dos Quadrados */
.quadrado {
  background: var(--marrom-claro);
  border: 0.125rem solid var(--bordo); /* Convertido de 2px */
  border-radius: 0.625rem; /* Convertido de 10px */
  padding: 1rem;
  width: 17.5rem; /* Convertido de 280px */
  height: 11.25rem; /* Convertido de 180px */
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* Convertido de 4px e 8px */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.quadrado-title {
  font-size: 1.8rem;
  color: var(--preto);
  margin-bottom: 0.5rem;
}

.quadrado-description {
  font-size: 1.2rem;
  color: var(--preto);
  text-align: center;
  margin: 0;
  line-height: 1.4;
}

/* Animação no hover */
.quadrado:hover {
  transform: translateY(-0.625rem) rotate(2deg) scale(1.05); /* Convertido de 10px */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); /* Convertido de 8px e 16px */
}

.quadrado:hover .quadrado-title {
  color: var(--bordo);
  transition: color 0.3s ease;
}

.quadrado:hover .quadrado-description {
  font-weight: 600;
  transition: font-weight 0.3s ease;
}

/* Animação de Brilho no Título */
@keyframes brilho {
  0%, 100% {
    text-shadow: 0 0 0.3125rem rgba(255, 255, 255, 0.2), 0 0 0.625rem rgba(255, 255, 255, 0.1); /* Convertido de 5px e 10px */
  }
  50% {
    text-shadow: 0 0 1.25rem rgba(255, 255, 255, 0.8), 0 0 1.875rem rgba(255, 255, 255, 0.5); /* Convertido de 20px e 30px */
  }
}

.quadrado-title.animar {
  animation: brilho 1.5s ease-in-out infinite;
  color: var(--bege-claro);
}

/* PDFs Container */

.oficinas-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem; /* Conversão para rem */
}

.titulo-oficinas {
  font-size: 2.4rem; /* Conversão para rem */
  color: var(--bordo);
  font-family: 'Dancing Script', cursive;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.descricao-oficinas {
  font-size: 1.6rem; /* Conversão para rem */
  color: var(--preto);
  margin-bottom: 2rem;
  font-family: 'Montserrat', sans-serif;
}

.pdf-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem; 
  justify-items: center;
  padding: 0; 
  list-style: none;
}

.pdf-item {
  background: var(--bege-claro);
  border: 0.0625rem solid var(--marrom-claro); /* Convertido de 1px */
  border-radius: 0.5rem; /* Convertido de 8px */
  padding: 1rem;
  width: 100%; 
  max-width: 25rem; /* Convertido de 400px */
  text-align: center;
  transition: transform 0.3s ease, background 0.3s ease;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* Convertido de 4px e 8px */
}

.pdf-item:hover {
  background: var(--hover-card);
  transform: translateY(-0.3125rem); /* Convertido de 5px */
}

.pdf-link {
  text-decoration: none;
  color: var(--preto);
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-link:hover {
  color: var(--bordo);
}

.pdf-link::before {
  content: '📄'; 
  margin-right: 0.5rem; /* Convertido de 8px */
  font-size: 1.4rem;
}

/* Layout Responsivo */
@media (max-width: 48rem) {
  .pdf-list {
    grid-template-columns: 1fr;
  }
  .pdf-item {
    padding: 1.2rem;
    width: 100%;
  }
}

/* Footer */
.site-footer {
  background: var(--preto);
  padding: 2rem;
  color: var(--bege-claro);
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
}

.footer-left .footer-site-name {
  font-family: 'Dancing Script', cursive;
  font-size: 1.2rem;
  color: var(--bege-claro);
  margin: 0;
}

.footer-contact {
  text-align: right;
}

.footer-contact h4 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: var(--bege-claro);
}

.footer-contact ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.625rem; /* Convertido de 10px */
  justify-content: flex-end;
}

.social-icons li a {
  text-decoration: none;
  color: var(--bege-claro);
  transition: transform 0.3s ease, color 0.3s;
}

.social-icons li a:hover {
  transform: scale(1.1);
  color: var(--marrom-claro);
}

.icon-lattes {
  width: 1.75rem; /* Convertido de 28px */
  height: 1.75rem;
  transition: transform 0.3s ease;
}

.icon-lattes:hover {
  transform: scale(1.1);
}

/* Footer inferior */
.footer-bottom {
  text-align: center;
  font-size: 1.2rem;
  color: var(--bege-claro);
  padding-top: 1rem;
  border-top: 1px solid #ffffff33;
}

.footer-bottom p {
  margin: 0;
  font-size: 1rem;
}

/* Lampejo */
.lampejo-container {
  padding: 1.25rem; /* Convertido de 20px */
  font-size: 1.2rem;
  color: var(--preto);
  background-color: var(--bege-claro);
}

.lampejo-container h1 {
  font-family: 'Dancing Script', cursive;
  color: var(--bordo);
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.lampejo-container p {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.lampejo-container blockquote {
  font-style: italic;
  color: var(--marrom-claro);
  margin: 1.5rem 0;
  padding-left: 1.5rem;
  border-left: 0.25rem solid var(--bordo); /* Convertido de 4px */
}

/* Centralizar o título principal e os subtítulos */
.lampejo-title-centered {
  text-align: center;
}

/* Cor da linha divisória */
.divider {
  border: none;
  height: 0.125rem; /* Convertido de 2px */
  background-color: #803D3B;
  margin: 1.25rem 0; /* Convertido de 20px */
}

.lampejo-subtitle {
  font-family: "Dancing Script", cursive;
  font-size: 2rem; /* Convertido de 32px */
  font-weight: 800;
  color: #803D3B;
}

.lampejo-text {
  text-align: justify;
}

/* Animações */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.quadrado {
  background: var(--marrom-claro);
  border: 0.125rem solid var(--bordo); /* Convertido de 2px */
  border-radius: 0.625rem;
  padding: 1rem;
  width: 17.5rem; /* Convertido de 280px */
  height: 11.25rem; /* Convertido de 180px */
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  animation: slideIn 0.8s ease forwards;
}

.quadrado:nth-child(2) {
  animation-delay: 0.2s;
}

.quadrado:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dropDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.site-title {
  opacity: 0;
  animation: dropDown 1s ease forwards;
}

.navbar {
  opacity: 0;
  animation: dropDown 1s ease forwards;
  animation-delay: 0.2s;
}

/* Estilos para a página Sobre a Pesquisadora */
.profhistoria-container {
  padding: 2rem;
}

.grupo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: var(--bege-claro); /* Fundo principal */
}

.sobre-container {
  padding: 2rem;
}

.sobrepesquisa-container {
  padding: 2rem;
  max-width: 60rem; /* Limita a largura para melhorar a legibilidade */
  margin: 0 auto;
}


.titulo-pagina {
  font-family: 'Dancing Script', cursive;
  font-size: 3rem;
  color: var(--bordo);
  text-align: center;
  margin-bottom: 2rem;
}

.grupo-conteudo {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}

.sobre-conteudo {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
}

.fotos-coluna {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.logo-profhistoria {
  width: 100%;
  max-width: 20rem; /* Ajuste conforme necessário */
  height: auto;
  object-fit: contain;
}

.foto-paisagem {
  width: 100%;
  height: auto;
  border-radius: 0.5rem; /* Bordas arredondadas */
}

.foto {
  width: 100%;
  height: auto;
  border-radius: 0.5rem; /* Borda arredondada, opcional */
  padding: 0;
  object-fit: cover; /* Garante que a imagem preencha o espaço sem distorcer */
}

.foto-quadrado {
  width: 30rem; /* Ajuste para o tamanho desejado */
  height: 30rem; /* Ajuste para o tamanho desejado */
  background-color: var(--marrom-claro); /* Cor de fundo para quando a imagem está carregando */
  border-radius: 0.5rem;
  padding: 0;
  overflow: hidden; /* Garante que a imagem fique dentro dos limites */
}

/* Coluna de texto */
.texto-coluna {
  flex: 2;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--preto);
  text-align: justify;
}
.poema p {
  margin: 0; /* Remove o espaçamento padrão entre as linhas */
  line-height: 1.6; /* Controla o espaçamento entre linhas */
}

.estrofe {
  margin-bottom: 1.5rem; /* Espaço entre as estrofes */
}

.texto p {
  margin-bottom: 1.5rem; /* Espaçamento entre os parágrafos */
}

.texto-pesquisa{
  text-align: justify;
  font-size: 1.2rem;
}

blockquote {
  font-style: italic;
  color: var(--marrom-claro);
  margin: 1.5rem 0;
  padding-left: 1.5rem;
  border-left: 4px solid var(--bordo);
}

blockquote span {
  display: block;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  color: var(--preto);
  text-align: right;
}

/* Animação de entrada (slide-in e fade-in) */
@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Desliza para cima ao entrar */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeInSlide 1s ease forwards;
}

/* Layout Responsivo e Ajustes para Telas Menores */
@media (max-width: 768px) {
  /* Navbar Responsiva */
  /* Estilos para o ícone do hambúrguer */
.hamburguer-menu {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
}

.hamburguer-menu span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: var(--bege-claro); /* Troque a cor preta pelo Bordô */
  margin: 5px 0;
}

  .navbar ul {
    display: none;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--bordo);
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .navbar ul.active {
    display: flex;
  }

  .hamburguer-menu {
    display: block;
  }

  /* Dropdown dentro da Navbar */
  .dropdown-menu {
    display: none;
    flex-direction: column;
    background: var(--marrom-claro);
    position: relative;
    gap: 0;
  }

  .dropdown:hover .dropdown-menu {
    display: flex;
    position: static;
    opacity: 1;
  }

  /* Centralização e redução do título principal */
  .site-title, .site-subtitle {
    text-align: center;
    font-size: 2rem;
  }

  /* Quadrados em uma coluna */
  .quadrados-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  /* Footer em coluna e fontes menores */
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    padding: 0 1rem;
  }

  .footer-contact ul {
    justify-content: center;
  }

  .footer-bottom {
    font-size: 0.9rem;
    padding-top: 1rem;
    border-top: 1px solid #ffffff33;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }

  .footer-bottom p {
    margin: 0;
    line-height: 1.4;
  }

  .site-footer {
    padding: 1.5rem 0; /* Ajuste de padding para evitar cortes nas laterais */
  }

  .lampejo-title lampejo-title-centered {
    font-size: 1rem;
  }


  .pdf-button {
    display: inline-block;
    background-color: #803D3B; /* Bordô */
    color: #E4C59E; /* Bege Claro */
    padding: 12px 20px;
    margin: 10px 0;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .pdf-button:hover {
    background-color: #AF8260; /* Marrom Claro */
  }

  .sobre-conteudo {
    flex-direction: column;
  }

  .foto-coluna {
    order: 2; /* Coloca a imagem abaixo do texto */
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  .texto-coluna {
    order: 1; /* Garante que o texto venha antes da imagem */
    font-size: 1rem;
  }

  .titulo-pagina {
    font-size: 1.5rem;
  }
  
}